// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-issue-tsx": () => import("/Users/donnyrimer/Desktop/DB/PRILUSBY/src/templates/issue.tsx" /* webpackChunkName: "component---src-templates-issue-tsx" */),
  "component---src-templates-article-tsx": () => import("/Users/donnyrimer/Desktop/DB/PRILUSBY/src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-pages-about-tsx": () => import("/Users/donnyrimer/Desktop/DB/PRILUSBY/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-all-tsx": () => import("/Users/donnyrimer/Desktop/DB/PRILUSBY/src/pages/all.tsx" /* webpackChunkName: "component---src-pages-all-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/donnyrimer/Desktop/DB/PRILUSBY/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pastissues-tsx": () => import("/Users/donnyrimer/Desktop/DB/PRILUSBY/src/pages/pastissues.tsx" /* webpackChunkName: "component---src-pages-pastissues-tsx" */)
}

