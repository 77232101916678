module.exports = [{
      plugin: require('/Users/donnyrimer/Desktop/DB/PRILUSBY/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/donnyrimer/Desktop/DB/PRILUSBY/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/donnyrimer/Desktop/DB/PRILUSBY/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-28181852-23","head":false,"anonymize":true},
    }]
